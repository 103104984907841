import { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const UserContext = createContext()

function UserProvider({ children }) {
    const [accessToken, setAccessToken] = useState();
    const [refreshToken, setRefreshToken] = useState();

    const [categorias, setCategorias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [tipos, setTipos] = useState([
        { id: 1, nombre: "Empleos", pregunta: "¿Qué empleo necesitas?" },
        { id: 2, nombre: "Servicios", pregunta: "¿Qué servicio necesitas?" },
        { id: 3, nombre: "Productos", pregunta: "¿Qué producto necesitas?" },
    ])

    const [categoria, setCategoria] = useState(null);
    const [localidad, setLocalidad] = useState(null);
    const [tipoSelected, setTipoSelected] = useState(tipos[1])


    const [loading, setLoading] = useState(true);

    const [actualPage, setActualPage] = useState(null);

    useEffect(() => {
        if (actualPage === 'inicio') {
            const url = new URL(window.location);
            let actualizado = false;

            if (categoria) {
                if (categoria.id !== parseInt(url.searchParams.get("categoria"))) {
                    url.searchParams.set("categoria", categoria.id);
                    actualizado = true;
                }
            } else {
                if (url.searchParams.has("categoria")) {
                    url.searchParams.delete("categoria");
                    actualizado = true;
                }
            }

            if (localidad) {
                if (localidad.id !== parseInt(url.searchParams.get("localidad"))) {
                    url.searchParams.set("localidad", localidad.id);
                    actualizado = true;
                }
            } else {
                if (url.searchParams.has("localidad")) {
                    url.searchParams.delete("localidad");
                    actualizado = true;
                }
            }

            if (actualizado) {
                window.history.pushState({ categoria, localidad }, '', url);
            }
        }
    }, [categoria, localidad, actualPage]);

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoriaId = searchParams.get('categoria');
        const localidadId = searchParams.get('localidad');

        if (categoriaId) {
            let foundCategoria = categorias.find(categoria => categoria.id === parseInt(categoriaId));

            if (foundCategoria) {
                setCategoria(foundCategoria);
            } else {
                setCategoria(null);
            }

        } else {
            setCategoria(null);
        }

        if (localidadId) {
            let foundLocalidad = localidades.find(localidad => localidad.id === parseInt(localidadId));

            if (foundLocalidad) {
                setLocalidad(foundLocalidad);
            } else {
                setLocalidad(null);
            }
        } else {
            setLocalidad(null);
        }
    }, [location.search]);


    useEffect(() => {
        let accessTokenStorage = localStorage.getItem('accessToken');
        let refreshTokenStorage = localStorage.getItem('refreshToken');

        if (accessTokenStorage) {
            setAccessToken(accessTokenStorage);
        }

        if (refreshTokenStorage) {
            setRefreshToken(refreshTokenStorage);
        }

        setLoading(false);
    }, []);

    function getAccessToken() {
        if (loading) return localStorage.getItem('accessToken');
        return accessToken;
    }

    function getRefreshToken() {
        if (loading) return localStorage.getItem('refreshToken');
        return refreshToken;
    }

    function logout() {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        setAccessToken()
        setRefreshToken()
    }

    function handleSetAccessToken(token) {
        setAccessToken(token);
        localStorage.setItem("accessToken", token);
    }

    function handleSetRefreshToken(token) {
        setRefreshToken(token);
        localStorage.setItem("refreshToken", token);
    }

    function login(respuesta) {
        handleSetAccessToken(respuesta.token.access);
        handleSetRefreshToken(respuesta.token.refresh);
    }

    return (
        <UserContext.Provider value={{
            getAccessToken, setAccessToken, getRefreshToken, setRefreshToken, logout, accessToken, login, handleSetAccessToken, handleSetRefreshToken,
            categorias, setCategorias, localidades, setLocalidades, categoria, setCategoria, localidad, setLocalidad, tipoSelected, setTipoSelected,
            actualPage, setActualPage, tipos
        }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };